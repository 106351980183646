
import { defineComponent, defineAsyncComponent, computed } from "vue";
import { useRoute } from "vue-router";

const CatTryoutMain = defineAsyncComponent(
  () => import(`@/features/cat-tryout/main.vue`)
);

export default defineComponent({
  components: {
    CatTryoutMain,
  },
  setup() {
    const route = useRoute();

    const cpnsCode = computed(() => route.params?.slug as string);
    const catCode = computed(() => route.query?.catCode as string);

    return {
      cpnsCode,
      catCode,
    };
  },
});
